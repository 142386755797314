
@font-face {
  font-family: 'Domine';
  font-weight: 1000;
  src: local('Domine-Bold'),
    url(./assets/fonts/Domine-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Inter';
  font-weight: 400;
  src: local('Inter-Regular'),
    url(./assets/fonts/Inter-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Inter';
  font-weight: 600;
  src: local('Inter-SemiBold'),
    url(./assets/fonts/Inter-SemiBold.ttf) format('truetype');
}
